.sokBtn-innerDiv{
    width: 26%;
}

.sokBtn-outerDiv{
    width: 91.5%;

}

.invoiceView-btn{
    padding: 10px;
    width: 111px;
    border-radius: 25px;
    background-color: #95B8D1 !important;
    color: white;
    border: none;
    margin-bottom: 1rem;

}

.invoice-dynamic-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.deleteBtn{
    background-color: red !important;
}

.sokBtn{
    background-color: #95B8D1 !important;
}
.view-invoice-btn{
    background-color: #95B8D1;
    color: white;
    border: none;
    padding: 05px 10px;
    border-radius: 7px;
}
.view-invoice-btn:hover{
    background: #244055 !important;
}
.view-invoice-btn:active{
    background: #95B8D1 !important;
}
.sokBtn:hover{
    background: #244055 !important;
}
.sokBtn:active{
    background: #95B8D1 !important;
}
@media (max-width: 745px){
    .sokBtn-innerDiv{
        width: 100%;
    }
    .invoice-form-col{
        width: 100% !important;
    }
    
}
@media (max-width: 769px){
    .col-for-id{
        margin-left: 300px;
        
    }
    .table-rows-info{
        width: 90%;
    }
    .table-rows-heading{
        width: 100%;
    }
    
}

