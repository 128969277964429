.company-card-btn {
    background-color: #95B8D1;
    padding: 8px 12px;
    border-radius: 10px;
    color: white;
    margin-right: 20px;
    border: none;
    border: none;
}
.heading-div {
    width: 81%;
    height: 92px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 28px;
    flex-wrap: wrap;
}
.company-info-row1{
    flex-wrap: wrap;
    width: 95%;
}
.company-info-row2{
    flex-wrap: wrap;
   
}

@media (max-width: 768px){
    .company-info-row1, .company-info-row2, .form-input {
        width: 100%;
    }
    .form-heading{
        margin: 0px;
    }
    }
  