.mail-main-container{
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: start;
}
.field-div{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.sendBtn-div{
    display: flex;
}
.send-mail-btn:hover{
    background-color: #6060a3;
}
.send-mail-btn{
    padding: 8px 26px;
    border-radius: 15px;
    border: 1px solid #6060a3;
    color: white;
    background-color: #95B8D1;
}
@media (max-width: 742px){
    .input-field{
        width: 100% !important;
    }
}