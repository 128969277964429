* {
    font-family: "Century Gothic";
}

.offcanvas-close-div{
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 25px 30px;
}

.user-prof-div img{
    object-fit: contain;
}
.user-prof-div{
    display: flex;
    color: white;
    align-items: center;
}
.dashboard-div{
    display: flex;
    color: white;
    justify-content: space-around;
    width: 146px;
}
.dashboard-div img{
    object-fit: contain;
}

.linkId{
    text-decoration: none !important;
    color: white;
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.userprof-heading{
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-left: 12px;
    margin-bottom: 0px;
}
.dashboard-heading{
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 0px;
    padding-left: 10px;
}
.navbar{
    background-color: #153243 !important;
}
.navbar-toggler{
    border: 2px solid #8992a7;
}
.sidebar-menu{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #153243 !important;
    color: white;
}

.user-prof-div-side-menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    color: white;
}
.user-prof-div-side-menu img{
    object-fit: contain;
}
.dashboard-div-side-menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 28px;
}
.dashboard-div-side-menu img{
    object-fit: contain;
}
.userprof-heading-side-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 4px;
}
@media (max-width: 745px) {
    .dashboard-div,
    .user-prof-div,
    .dropdown {
        display: none !important;
    }

    .navbar-toggler {
        display: block !important;
    }

    .dashboard-div-side-menu,
    .user-prof-div-side-menu,
    .dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    .globalworker-img{
        display: flex;
        justify-content: center;
    }
    .container-fluid{
        display: flex;
        justify-content: center; 
    }
}