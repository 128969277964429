.invoice-btn-new-div{
    margin: 25px 0px 25px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media(max-width: 745px){
    .invoice-View-Buttons{
        margin: 3px 8px 3px 0px;
    }

}