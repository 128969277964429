.client-info-main-container{
    width: 90%;
    
}

.client-info-radio-container{
    display: flex;
    flex-wrap: wrap;

}

.Privatpersons-container{
    margin-right: 29px;
}

.Privatpersons-heading{
    display: flex;
}


.företags-kund-heading{
    display: flex;
}
.inv-dropdown-div{
    display: flex;
}
.label{
    text-align: justify;
}
.save-btn{
    width: 100%;
    background-color: #95B8D1;
    color: white;
}
.save-btn:hover{
    background-color: #80a1b8;
    color: white;
}
.save-btn-div{
    width: 18% !important;
    display: flex;
    margin-left: 40px;

}
.sterikRed{
    color: red;
}

.bank-radio-btn-container{
    display: flex;
    flex-direction: column;

}
.bank-radio-heading{
    display: flex;
}
.bank-info-radio-btn{
    display: flex;
    flex-direction: column;
}

.bank-info-container{
    display: flex;
/* flex-direction: column; */
margin-bottom: 12px;

}
.bank-info-read-more-btn{
    margin-left: 0.5rem;
    background-color: #FFD174;
        border-radius: 20px;
        border: none;
        color: white;
        padding: 8px 23px;
}
.bank-info-read-more-btn:hover{
    background-color: #e0b764;

}
.fl-bank-input-field{
    width: 45%;
}
@media (max-width: 751px){
    .bank-info-container{
        display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    }
}
@media (max-width: 745px) {
    .save-btn-div{
        width: 50% !important;
    }
    .Privatpersons-container{
        margin-bottom: 29px;
    }
    .bank-info-read-more-btn{
        width: 128px;
    }
    .fl-bank-input-field{
        width: 100% !important;
    }
}