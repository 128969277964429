.invoice-main-container{
    width: 100%;
    position: relative;
    bottom: 2px;
    display: flex;
    margin-top: 200px;

}
.invoice-multi-heading-div{
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: end;

}
.invoice-list-div{
    width: 35%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
}

.invoice-multi-headin{
    padding: 10px 5px;
    border-radius: 5px;
    color: white;
}
.inv-info-heading-row{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.yellowHeading{
    background-color: #dba435;
}
.brightRed{
    background-color: red;
}
.redHeading{
    background-color: #c56247;

}
.blueHeading{
    background-color: #457191;

}
.greyHeading{
    background-color: #aaaaaa;
}
.invoice-details{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.invoice-list-div-inner{
    width: 80%;
}
.txtRed{
    color: #d34a25;
}
.forTxtWrap{
    overflow-wrap: anywhere;
}

.updtStatusDropdownContainer{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}

.updtStatusDropdownBtn{
    width: 100% !important;
    background-color: white !important;
    opacity: 1 !important;
    color: black !important;
    border-radius: 5px;
}

.updateFakturaBtn{
    background-color: #153243;
    padding: 10px 13px;
    border-radius: 10px;
}

@media (max-width: 745px) {
    .custHr{
        margin-top: 112px;

    }
    .invoice-multi-heading-div{
        width: 100%;
    }
    .invoice-main-container{
        flex-direction: column-reverse;
        
    }
    .invoice-list-div{
        width: 100%;
    }
    
}