.member-form-btn-div{
    height: 69px !important;
    width: 24%;
    display: flex;
    align-items: flex-end;
}
.members-form{
    width: 75%;
}
.mem-email-container{
    width: 54%;
}
.mem-label{
    display: flex;
}
.mem-form-btn{
    background-color: #95B8D1;
    color: white;
}
.mem-form-btn:hover{
    background-color: #7293aa;

}
.client-data-col{
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
@media (max-width: 745px) {
    .members-form{
        flex-direction: column;
    }
    .member-form-btn-div{
        justify-content: center;
        align-items: flex-start;
        width: 84%;
    }
    .mem-email-container{
        width: 84%;
    }
    .client-list-row {
        width: 655px;
        overflow: auto;
        display: flex;
        flex-wrap: nowrap;
      }
      .client-data-col-div{
        justify-content: start;
      }
      .client-data-col{
        padding: 0px 35px;
      }
    
}