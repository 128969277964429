.generatedInvoiceContainer{
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.g-invoiceHeader-col{
    border: 1px solid #09bdc5;
}

.salaryNumber-container{
    width: 80%;
    display: flex;
    justify-content: end;
}

.totalAmount-container{
    width: 100%;
    display: flex;
    justify-content: end;
}
.totalAmount-container td {
    padding: 0px 30px;
}
.totalAmount-container th {
    padding-left: 20px;
    padding-right: 20px;
}
.g-invoiceHeader{
    display: flex;
    width: 80%;
    justify-content: space-between;
}
.g-invoiceHeader img{
    object-fit: contain;
}

.invoiceCustomerDetailsTable{
    width: 100%;
}
.invoiceCustomerDetailsTable th{
    text-align: left;
}
.invoiceCustomerDetailsTable td{
    text-align: left;
}


table {
 border-collapse: collapse;
}

table, th, td {
 padding: 5px;
 text-align: center;
}

.invoiceAmountContainer{
    width: 80%;
}
/* .invoiceAmountContainer table tbody tr th{
    border: 1px solid black;
}
.invoiceAmountContainer table tbody tr td{
    border: 1px solid black;
}
.invoiceAmountContainer table tbody{
    border: 1px solid black;
} */
.invoiceAmountContainer table{
    border-top: 1px solid #09bdc5;
    border-bottom: 1px solid #09bdc5;
}


.invoiceAmountTable{
    width: 100%;
}

.invoice-note-container{
    width: 80%
}

.invoice-bankInfo-container{
    width: 80%;
}


.invoice-bankInfo-container td {
    width: 60%;
}
.invoice-bankInfo-container th {
    width: 40%;
}

.invoice-bankInfo-table{
    width: 100%;
}

.invoiceCustomerDetails{
    width: 80%;
}

.invoice-bankInfo-table th{
    text-align: left;
}
.invoice-bankInfo-table td{
    text-align: left;
}

.address-footer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}