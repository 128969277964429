* {
    font-family: "Century Gothic";
}

.main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
}

.left-container {
    width: 40%;
    padding: 30px; /* Padding for better spacing */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.left-container > * {
    margin-bottom: 15px; /* Increased margin between elements */
}

.form-check {
    display: flex;
    align-items: center;
}

.form-check label {
    margin-bottom: 0; /* Remove default margin for better alignment */
    margin-left: 5px; /* Add spacing between checkbox and label */
    
}

.right-container {
    width: 50%;
    height: 500px;
}

.right-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#login-heading {
    background-color: #153243;
    color: white;
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    width: fit-content;
}

/* Custom style to increase submit button width */
.btn-primary {
    width: 100%; /* Set width to 100% */
}
#login-btn{
    background-color: #153243;
}
#divider{
    color: #153243;
    width: 30%;
    margin-top: 0px;
    padding-top: 0px;
}
.form-lebels{
    margin: 0px;
    font-weight: bold;
}
.float-end{
    font-weight: bold;
}
.main-check-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
@media only screen and (max-width: 767px) {
    .left-container {
        width: 90%; /* Adjust the width based on your preference */
    }
    .nav-bar-cont{
        display: flex !important;
        justify-content: center !important;
    }
}